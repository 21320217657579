import './App.css';
import {AppRouter} from './router/AppRouter'
import { UserProvider } from './context/UseProvider';
import React from "react";

function App() {

   
  return (
    <UserProvider>          
      <AppRouter/>     
    </UserProvider>
  );
}

export default App;
