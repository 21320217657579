import { UserContext } from "./UserContext"
import React, { useState, useEffect }  from "react";


export const UserProvider = ({ children })=>{
    const [users, setUsers] = useState([]);
  

    
    useEffect(() => {
        const storedUser = localStorage.getItem('users');       
        if (storedUser) {
            try {
                setUsers(JSON.parse(storedUser));
            }
            catch (error) {
                console.log('error al parsear', error);
                setUsers(null)
            }
        }     

 
    }, []);
    
    return(
        <UserContext.Provider value={{ users,setUsers}}>
            { children }
        </UserContext.Provider>
    )
}